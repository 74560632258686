import { setterAmount } from '@/plugins/utils';

export interface AccountingModelImpl {
  date: string;
  billing_currency: string;
  billing_country: string;
  income: number;
  refund: number;
  total: number;
}

export class AccountingModelDTO implements AccountingModelImpl {
  date = '';
  billing_currency = '';
  billing_country = '';
  income = 0;
  refund = 0;
  total = 0;
}

export default class AccountingModel extends AccountingModelDTO {
  constructor(dto: AccountingModelDTO) {
    super();
    Object.assign(this, dto);
  }

  generateForExcel = () => {
    return {
      Date: this.date,
      Currency: this.billing_currency,
      Country: this.billing_country,
      Income: this.getIncomeFormated(),
      Refund: this.getRefundFormated(),
      Total: this.getTotalsFormated(),
    };
  };

  getIncomeFormated = () => this.generatePriceFormat(setterAmount(this.income));
  getRefundFormated = () => this.generatePriceFormat(setterAmount(this.refund));
  getTotalsFormated = () => this.generatePriceFormat(setterAmount(this.total));

  private generatePriceFormat = (amount: string) => amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
}
