











import { Component, Vue, Prop } from 'vue-property-decorator';
import AccountingModel from '@/models/Accounting.model';

interface GlobalObj {
  [key: string]: GlobalObj;
}

@Component
export default class BlockTotals extends Vue {
  @Prop({ required: true, type: Array })
  private accountingList!: AccountingModel[];

  get listTotals(): AccountingModel[] {
    if (this.accountingList.length > 0) {
      return this.groupAndSum(this.accountingList);
    } else return [];
  }

  private groupAndSum(list: AccountingModel[]): AccountingModel[] {
    const reduced = list.reduce(function(arrayNuevosValores, viejo) {
      const modeloExistente = arrayNuevosValores.filter(
        accountingItem => accountingItem.billing_currency == viejo.billing_currency,
      );
      if (modeloExistente.length > 0) {
        modeloExistente[0].income += viejo.income;
        modeloExistente[0].refund += viejo.refund;
        modeloExistente[0].total = modeloExistente[0].income - modeloExistente[0].refund;
      } else {
        const nuevoModelo = new AccountingModel({
          date: '',
          billing_country: '',
          billing_currency: viejo.billing_currency,
          refund: viejo.refund,
          income: viejo.income,
          total: viejo.total,
        });
        arrayNuevosValores.push(nuevoModelo);
      }
      return arrayNuevosValores;
    }, [] as AccountingModel[]);

    return reduced;
  }
}
