import API from '../repository';
import AccountingModel, { AccountingModelDTO } from '@/models/Accounting.model';

const RESOURCE = '/accounting';

export default {
  async getAccounting({ country = '', group = 'month', date = '' }): Promise<{ accounting: AccountingModel[] }> {
    const { accounting } = await API.post(`${RESOURCE}`, {
      country,
      group,
      date,
    });
    const accountingModels = accounting.map((accountingDto: AccountingModelDTO) => new AccountingModel(accountingDto));
    return {
      accounting: accountingModels,
    };
  },
};
