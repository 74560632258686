














































































import { Component, Vue } from 'vue-property-decorator';
import BlockTotals from '@/components/Accounting/BlockTotals.vue';
import ListAccounting from '@/components/Accounting/ListAccounting.vue';

import AccountingRepository from '@/repository/modules/accounting.repository';
import InvoicesRepository from '@/repository/modules/invoices.repository';
import AccountingModel from '@/models/Accounting.model';
import InvoicesListModel from '@/models/InvoicesList.model';

import XLSX from 'xlsx';

@Component({
  components: {
    BlockTotals,
    ListAccounting,
  },
})
export default class Accounting extends Vue {
  private accountingList: AccountingModel[] = [];
  private invoicesList: InvoicesListModel[] = [];
  groupBy = 'month';
  country = '';
  date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
  loading = false;

  async mounted(): Promise<void> {
    await this.getAccountingData();
  }

  async getAccountingData(): Promise<void> {
    this.loading = true;
    const { accounting } = await AccountingRepository.getAccounting({
      country: this.country,
      group: this.groupBy,
      date: this.date,
    });
    this.accountingList = accounting;
    await this.getInvoicesData();
    this.loading = false;
  }

  async getInvoicesData(): Promise<void> {
    this.loading = true;
    const { invoicesList } = await InvoicesRepository.findInvoices({
      country: this.country,
      monthDate: this.date,
    });
    this.invoicesList = invoicesList;
    console.log(invoicesList);
    this.loading = false;
  }

  clearAndfind() {
    this.country = '';
    this.groupBy = 'month';
    this.date = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`;
    this.getAccountingData();
  }

  async generateXlsx() {
    const dataFormatedForExcel = XLSX.utils.json_to_sheet(this.accountingList.map(item => item.generateForExcel()));
    const wb = XLSX.utils.book_new(); // creamos un nuevo "Libro" de excel
    // añadiremos una hoja a nuestro nuevo libro
    // un libro puede contener mas de uabn hoja
    // wb : Libro
    // dataFormatedForExcel: la informacion en formato Object json
    // nombre del fichero resultante
    XLSX.utils.book_append_sheet(wb, dataFormatedForExcel, `${this.groupBy} ${this.country} ${this.date}`);
    // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons'); //De este modo añadiriamos una hoja dos  llamada pokemons

    // Direct Download
    XLSX.writeFile(wb, `${this.groupBy}_${this.country}${this.date}.xlsx`);
  }

  async downloadInvoiceByID(invoice_id: string, id_num: string) {
    await InvoicesRepository.downloadInvoice(invoice_id, id_num);
  }

  async downloadAllInvoices() {
    await InvoicesRepository.downloadAllInvoices({
      country: this.country,
      monthDate: this.date,
    });
  }

  get listAccounting(): AccountingModel[] {
    if (this.accountingList.length > 0) return this.accountingList;
    else return [];
  }

  get listInvoices(): InvoicesListModel[] {
    if (this.invoicesList.length > 0) return this.invoicesList;
    else return [];
  }
}
