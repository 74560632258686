


























import { Component, Vue, Prop } from 'vue-property-decorator';
import AccountingModel from '@/models/Accounting.model';
@Component
export default class ListAccounting extends Vue {
  @Prop({ required: true, type: Array })
  private accountingList!: AccountingModel[];

  get listAccounting(): AccountingModel[] {
    if (this.accountingList.length > 0) return this.accountingList;
    else return [];
  }
}
